import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'

import { environment } from '~env'

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  standalone: true,
  imports: [MatButtonModule],
})
export class PageNotFoundComponent {
  get appUrl(): string {
    return environment.url
  }
}
