import { Routes } from '@angular/router'

import { PageNotFoundComponent } from './pages/errors/page-not-found/page-not-found.component'
import { guestGuard, clientsGuard } from './pages/pages.guard'

export const routes: Routes = [
  {
    path: 'errors/404',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/guest/guest.routes').then(r => r.routes),
    canMatch: [guestGuard],
  },
  {
    path: '',
    loadChildren: () => import('./pages/client/client.routes').then(r => r.routes),
    canMatch: [clientsGuard],
  },
  {
    path: '**',
    redirectTo: '/errors/404',
  },
]
