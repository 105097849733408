export const EVENT_NAME = {
  SIGN_IN: 'sign_in',
  SIGN_OUT: 'sign_out',
} as const

type EVENT_NAME = (typeof EVENT_NAME)[keyof typeof EVENT_NAME]

export function gtm(id: string) {
  const script = document.createElement('script')
  script.text = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${id}');`
  document.head.appendChild(script)

  const noscript = document.createElement('noscript')
  const iframe = document.createElement('iframe')
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`
  iframe.height = '0'
  iframe.width = '0'
  iframe.setAttribute('style', 'display:none;visibility:hidden')
  noscript.appendChild(iframe)
  document.body.insertBefore(noscript, document.body.firstChild)
}

export function setUserId(userId: string | null) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    user_id: userId,
  })
}
