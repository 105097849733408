import { Component, inject, OnInit } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AuthService } from '@libs/core/services/firebase/auth.service'
import { gtm, setUserId } from '@libs/gtm'
import { first } from 'rxjs'

import { environment } from '~env'

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'client'
  authSv = inject(AuthService)

  ngOnInit() {
    this.authSv
      .getCurrentUser()
      .pipe(first())
      .subscribe({
        next: user => {
          if (!user) {
            // not logged in
            return
          }
          return setUserId(user.uid)
        },
        complete: () => {
          gtm(environment.tagmanager.id)
        },
      })
  }
}
